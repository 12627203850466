<template>
	<div class="basic">
		<div class="title" style="vertical-align: center;"><img class="actor-image" :src="actor.imageURL" /> {{ actor.name }}</div>
		<div v-if="score.score">
			<div
				class="bigScore right"
				:class="{ 'good ': score.score >= 0, 'bad ': score.score < 0 }"
			>
				{{ (score.score > 0 ? "+" : "") + score.score }} ({{
					score.numCorrect
				}}/{{ score.numChoices }}) {{ score.percentage }}%
			</div>
		</div>
		<score-graph :scoreLines="scoreLines"></score-graph>

		<div class="divider">{{ actor.name }} Influencers</div>
		<div class="infRow" v-for="graph in infGraphs" v-bind:key="graph.name">
			<div class="feedName" :style="{ color: graph.color }">
				{{ graph.name }}
			</div>
			<div
				class="feedScore right"
				:class="{
					'good ': graph.score.score >= 0,
					'bad ': graph.score.score < 0,
				}"
			>
				{{ graph.score.score }} ({{ graph.score.numCorrect }}/{{
					graph.score.numChoices
				}}) {{ graph.score.percentage }}%
			</div>
			<influencer-graph :influencers="graph.influencers"></influencer-graph>
		</div>

		<div class="divider">{{ actor.name }} Choices</div>
		<choice-row
			:choice="choice"
			v-for="choice in choices"
			v-bind:key="choice.id"
		></choice-row>

		<div class="footer">
			<button class="goButton" @click="done()">Back</button>
		</div>
		<div class="footer-spacer" />
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import ScoreGraph from "@/components/ScoreGraph";
import InfluencerGraph from "@/components/InfluencerGraph";
import ChoiceRow from "@/components/ChoiceRow";

export default {
	name: "Actor",
	components: {
		ChoiceRow,
		InfluencerGraph,
		ScoreGraph
	},
	data() {
		return {
			actor: {},
			score: {},
			graph: {},
			infGraphs: [],
			choices: [],
			scoreLines: []
		};
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.scoreLines = JSON.parse(localStorage.getItem(`actor-${this.$route.params.id}-score-lines`));
			this.choices = JSON.parse(localStorage.getItem(`actor-${this.$route.params.id}-choices`));
			this.actor = await this.$services.getOne('actors', this.$route.params.id);
			// console.log("Actor is", this.actor)
			this.score = await this.$services.get(`score?actorID=${this.actor.id}`);
			const allFeeds = await this.$services.get("feeds");
			const scoreFeeds = allFeeds.map(async (feed) => {
				feed.score = await this.$services.get(`score?actorID=${this.actor.id}&feedID=${feed.id}`);
				const scores = await this.$services.get(`score?actorID=${this.actor.id}&feedID=${feed.id}&daily`);
				if (scores.length > 0) {
					//Get the Influencers for their charts
					const data = await this.$services.get(`influencers/scored?actorID=${this.actor.id}&feedID=${feed.id}`);
					const influencers = data.filter(influencer => influencer.numChoices > 0);
					const infChart = {name: feed.name, color: feed.color, score: feed.score, influencers: influencers};
					this.infGraphs.push(infChart);
					this.infGraphs.sort((a, b) => a.name < b.name ? -1 : 1);
				}
				const retVal = {name: feed.name, color: feed.color, scores: scores};
				return retVal;
			});
			Promise.all(scoreFeeds).then(res => {
				this.scoreLines = res;
				localStorage.setItem(`actor-${this.$route.params.id}-score-lines`, JSON.stringify(this.scoreLines));
			})
			this.loadChoices();
		},
		loadChoices: async function () {
			this.choices = await this.$services.get(`choices/filtered?actorID=${this.actor.id}`);
			localStorage.setItem(`actor-${this.$route.params.id}-choices`, JSON.stringify(this.choices));
		},
		done() {
			this.$router.back();
		}
	}
}

</script>

<style scoped>
.actor-image {
	height: 25px;
}
</style>
